@font-face {
  font-family: 'Switzer';
  src: url('./fonts/Switzer-Variable.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Switzer';
  src: url('./fonts/Switzer-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Switzer';
  src: url('./fonts/Switzer-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Switzer';
  src: url('./fonts/Switzer-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Switzer';
  src: url('./fonts/Switzer-Extrabold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'BabasNeue';
  src: url('./fonts/BebasNeue-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GT America';
  src: url('./fonts/GT\ America\ Extended\ Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GT America';
  src: url('./fonts/GT\ America\ Extended\ Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Hobeaux';
  src: url('./fonts/Hobeaux-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
