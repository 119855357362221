.button {
  display: flex;
  height: 52px;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  width: min-content;

  border-radius: 16px;
  cursor: pointer;
}

.button:active {
  /*  Making button bigger on hover  */
  transform: scale(1.05) perspective(1px)
}

@media (hover: hover) and (pointer: fine) {
  .button:hover { transform: scale(1.05) perspective(1px); }
}
