.copyTitleArea {
  display: flex;
  width: min(560px, 80vw);
  justify-content: space-between;
}

.copyButtonArea {
  display: flex;
  gap: 6px;
  flex-direction: row;
}

.copyButtonIcon {
  width: auto;
  height: 14px;
  cursor: pointer;
}

.copyTextArea {
  display: flex;
  width: min(530px, 70vw);
  padding: min(20px, 7vw);
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  background: var(--white, #FFF);
  resize: none;
}