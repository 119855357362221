.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 2000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  justify-content: center;
  align-items: center;
}

.modalContent {
  position: relative;
  display: flex;
  width: min(500px, 70vw);
  padding: 40px 24px 32px 24px;
  flex-direction: column;
  align-items: center;
  gap: 40px 8px;
  border-radius: 16px;
  background: var(--black, #fff);
}
