
@keyframes bounce {
  0%, 100% {
    transform: translateY(-110px) translateX(-10px);
  }
  50% {
    transform: translateY(-115px) translateX(-10px);
  }
}


@keyframes bounce2 {
  0%, 100% {
    transform: translateY(-20px) scaleX(-1);
  }
  50% {
    transform: translateY(-28px) scaleX(-1);
  }
}

.bouncingArrow {
  animation: bounce 2s ease infinite;
}

.bouncingArrow2 {
  animation: bounce2 2s ease infinite;
}

.photoOptions {
  display: flex;
  justify-content: center;
  width: min(560px, 80vw);
  flex-wrap: wrap;
}

@media (max-width: 645px) {
  .photoOptions {
    gap: 32px;
  }
}
