.custom-tooltip {
  background-color: white;
  border: 1px solid #cccccc;
  padding: 10px;
  border-radius: 5px;
  pointer-events: auto;
  position: fixed;
}

.custom-tooltip .label {
  font-weight: bold;
}